.header-wrapper{
  padding-left: 6%;
   padding-right: 6%;
   box-sizing: border-box;
   box-shadow: 2px 4px 24px 0px #00000026;
   padding-top: 10px;
   padding-bottom: 10px;
   width: 100%;
   overflow-x: none;
   position: fixed;
   z-index: 1;
   background-color: white;
   display: flex
}


.hideItems{
  display: none;
}

.wrapper { 
  display: flex;
  flex-wrap: wrap;
  } 
  .colwrapper{
    width:33.3%;
  }
  .one{
    position: relative;
    overflow: hidden;
    margin-top: 2%;
    padding-left: 2%;
    padding-right: 2%;
    height: 250px;
  }
  .one img{
    width:100%;
    height: 100%;
  }
  .two{
    position: relative;
    overflow: hidden;
    margin-top: 2%;
    padding-left: 2%;
    padding-right: 2%;
    height: 500px;
  }
  .two img{
    width:100%;
    height: 100%;
  }
  
  
 @media screen and (max-width: 768px) { 
  .displayItems{
    display: none;
    cursor: pointer;
  }
  .hideItems{
    display: flex;
    justify-content: right;
  }
  .wrapper { 
    display: block;
    flex-wrap: wrap;
    } 
    .colwrapper{
      width:100%;
    }
    .one{
      position: relative;
      overflow: hidden;
      padding-left: 2%;
      padding-right: 2%;
      padding-top: 2%;
    }
    .one img{
      width:100%;
    }
    .two{
      position: relative;
      overflow: hidden;
      margin-top: 2%;
      padding-left: 2%;
      padding-right: 2%;
    }
    .two img{
      width:100%;
    }
  } 


@media (max-width: 1100px) {
  .displayItems{
    display: none;
  }
  .hideItems{
    display: flex;
    justify-content: right;
  }
}

@media(max-width:350px){
  .hideItems{
    display: block;
    margin-top: 20%;
    margin-left: -0%;
  }
}

 