.mainClassWrapper{
    width:'100%';
    padding-right: 6%;
    padding-left: 6%;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
}

.display-flex{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
     padding-top: 10px;
}

.besttourdetails{
    width: 80%;
     font-size: 18px;
     font-weight: 600;
}


.inputBox{
    width: 95%;
     height: 35px;
     border-radius: 7px;
     border: 1px solid #B7B7B7;
     padding-left: 10px;
     font-size: 18px;
     margin-top: 20px 
}

.dateInput{
    width: 100%;
     height: 35px;
     border-radius: 7px;
     border: 1px solid #B7B7B7;
     padding-left: 10px;
     font-size: 18px
}

.timetoholiday{
    font-size: '65px'; 
    font-weight: '600';
     display: "flex"; 
     justify-content: 'center'
    }
.parent {
    width: 100%;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    display: flex;
}

.daughter {
    width: 40%;
    text-align: center;
    box-sizing: border-box;
}

.son {
    width: 50%;
    padding-left: 5%;
    box-sizing: border-box;
}

.headingAbout{
    font-size: '50px';
    font-weight: '700';
    display: 'flex';
     justify-content: 'center';
     margin-top: '40px'
}

.mainClassAbout{
    width:100%;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    display: flex;
}

.aboutfirstClass{
    width:15%
}

.aboutsecondClass{
    width: 75%;
}

.happyClientEach {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin-top: '40px';
}

.contactUs {
    width: 100%;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    padding-left: 15%;
    padding-right: 15%;
}

.contactUsSelect{
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    padding-left: 15.3%;
    padding-right: 15.3%;
}

.contactUstextarea{
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    padding-left: 14.9%;
    padding-right: 14.3%;
}

.firstName {
    width: 100%;
}

.lastName {
    width: 100%;
}
.vertical{
    resize: vertical
}

.firstNameInput{
     width: '410px' !important;
      height: '35px'; border-radius: '7px' !important;
       border: '1px solid #B7B7B7';
        padding-left: '10px'; 
        font-size: '18px'; 
        display: 'flex';
         justify-content: 'end' ;
}

.footerMain{
    width: 100%;
    justify-content: space-around;
    box-sizing: border-box;
    display: flex;
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 4%;
}

.footerFirstContent{
    width: 50%;
    display: flex;
}

.footerSecondContent{
    width: 50%;
    display: flex;
}

.firstClass{
    width: 50%;
}
.secondClass{
    width: 50%;
    box-sizing: border-box;
    margin-left: 1.5%;
}

.thirdClass{
    width: 50%;
}

.forthClass{
    width: 50%;
}
.dialogboxmain{
    width: 100%;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 3%;
    padding-bottom: 1%;
}

.callnowbutton{
width:33.3%
}

.whatsappbutton{
    width:33.3%
}

.sendenquirybutton{
    width:33.3%
}

.modalwidth{
    position: 'absolute';
    top: '10%';
    left: '20%';
    border: '2px solid #000';
    box-shadow: 24;
    background-color: 'white';
    width: 700;
    outline: 'none'
    }
.modalCalender{
    display: flex;
    width:'100%';
    padding-right: 14.9%;
    padding-left: 14.3%;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
}
.arrivalDate{
    width:30%;
    margin-top: 20px;
}

.returnDate{
    width: 30%;
    margin-top: 20px;
}

    .backColoe{
        position: 'absolute';
        top: 14%;
        left: 0%;
        border: '2px solid #000';
        box-shadow: 24;
        background-color: 'white';
        width: 60%;
        outline: 'none'
    }
@media(max-width:1100px) {
    .parent {
        display: block;
    }

    .daughter {
        width: 100%;
        box-sizing: border-box;
    }

    .son {
        width: 100%;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
    }

    .displya-block {
        display: block;
    }

    .contactUs {
        display: block;
        box-sizing: border-box;
        padding-left: 6%;
        padding-right: 6%;
    }
    .contactUsSelect{
        padding-left: 7.6%;
        padding-right: 7%;
        box-sizing: border-box;
    }
    .contactUstextarea{
        padding-left: 8%;
        padding-right: 5.5%;
        box-sizing: border-box;
    }
    
    .firstName {
        width: 100%;
        text-align: center;
        box-sizing: border-box;
        margin-bottom: '20px';
    }

    .lastName {
        width: 100%;
        text-align: center;
        box-sizing: border-box;
        margin-top: '20px';
    }

    .input-width{
        width:'700px'
    }
    .dialogboxmain{
        width: 100%;
    }
    
    .callnowbutton{
    width:33%;
    display: flex;
    padding-top: 2%;
    justify-content: center;
    box-sizing: border-box;
    }
    
    .whatsappbutton{
        width:33%;
        padding-top: 2%;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
    }
    
    .sendenquirybutton{
        width:33%;
        padding-top: 2%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
    }

}

@media(max-width:950px) {
    .modalCalender{
        display: block;
        padding-left: 8%;
        padding-right: 8%;
        box-sizing: border-box;
    }
    .arrivalDate{
        width:100%;
        margin-top: 5px;
    }
    
    .returnDate{
        width:100%;
        margin-top: 10px;
    }
    .footerMain{
        width: 100%;
        display: block;
    }
    .footerFirstContent{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    
    .footerSecondContent{
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 8%;
        box-sizing: border-box;
    }
    .contactUs {
        padding-left: 4%;
        padding-right: 4%;
        box-sizing: border-box;
    }
    .contactUsSelect{
        padding-left: 5.5%;
        padding-right: 5.3%;
        box-sizing: border-box;
    }
    .contactUstextarea{
        padding-left: 5.5%;
        padding-right: 3.9%;
        box-sizing: border-box;
    }
    .dialogboxmain{
        width: 100%;
        display: block;
    }
    
    .callnowbutton{
    width:100%;
    display: flex;
    justify-content: center;
    }
    
    .whatsappbutton{
        width:100%;
        padding-top: 5%;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
    }
    
    .sendenquirybutton{
        width:100%;
        padding-top: 5%;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
    }
}

@media(max-width:768px) {

    .mainClassWrapper{
        padding-right: 16%;
        padding-left: 16%;
        box-sizing: border-box;
    }


    .besttourdetails{
         font-size: 16px;
    }

    .inputBox{
         height: 20px;
         border-radius: 5px;
         margin-top: 10px ;
         font-size: 15px
    }
    .dateInput{
         height: 20px;
         border-radius: 5px;
         font-size: 15px
    }
    
    .parent {
        display: block;
    }

    .daughter {
        width: 100%;
        box-sizing: border-box;
    }

    .son {
        width: 100%;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
    }

    .happyClientEach {
        width: 100%;
        height: fit-content;
        display: block;
        box-sizing: border-box;
    }

    .displya-block {
        display: block;
    }

    .contactUs {
        display: block;
    }
    .contactUsSelect{
        padding-left: 5%;
        padding-right: 5%;
        box-sizing: border-box;
    }
    .contactUstextarea{
        padding-left: 5.2%;
        padding-right: 4.2%;
        box-sizing: border-box;
    }

    .firstName {
        width: 100%;
        text-align: center;
        box-sizing: border-box;
    }

    .lastName {
        width: 100%;
        text-align: center;
        box-sizing: border-box;
    }

    .firstName input{
        width:'700px'
    }
    .footerMain{
        width: 100%;
        display: block;
    }
    .footerFirstContent{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    
    .footerSecondContent{
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 8%;
        box-sizing: border-box;
    }
    .firstClass{
        width: 100%;
    }
    .secondClass{
        width: 100%;
    }
    
    .thirdClass{
        width: 100%;
    }
    
    .forthClass{
        width: 100%;
    }
    
    .dialogboxmain{
        width: 100%;
        display: block;
    }
    
    .callnowbutton{
    width:100%;
    display: flex;
    justify-content: center;
    }
    
    .whatsappbutton{
        width:100%;
        padding-top: 5%;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
    }
    
    .sendenquirybutton{
        width:100%;
        padding-top: 5%;
        display: flex;
        box-sizing: border-box;
        justify-content: center;
    }

}



@media(max-width:570px) and (min-width:414px){
    .mainClassWrapper{
        padding-right: 4%;
        padding-left: 4%;
        box-sizing: border-box;
    }
    
    .footerMain{
        width: 100%;
        display: block;
    }
    .footerFirstContent{
        width: 100%;
        display: block;
        justify-content: center;
    }
    
    .footerSecondContent{
        width: 100%;
        display: block;
        justify-content: center;
    }
    .firstClass{
        width: 100%;
    }
    .secondClass{
        width: 100%;
        padding-top: 8%;
        box-sizing: border-box;
    }
    
    .thirdClass{
        width: 100%;
        padding-top: 8%;
        box-sizing: border-box;
    }
    
    .forthClass{
        width: 100%;
        padding-top: 8%;
        box-sizing: border-box;
    }

    .happyClientEach {
        width: 100%;
        height: '800px';
        display: block;
        box-sizing: border-box;
    }
    
}

@media(max-width:414px){
    .mainClassWrapper{
        padding-right: 2%;
        padding-left: 2%;
        box-sizing: border-box;
    }
    .footerMain{
        width: 100%;
        display: block;
    }
    .footerFirstContent{
        width: 100%;
        display: block;
        justify-content: center;
    }
    
    .footerSecondContent{
        width: 100%;
        display: block;
        justify-content: center;
    }
    .firstClass{
        width: 100%;
    }
    .secondClass{
        width: 100%;
        padding-top: 8%;
        box-sizing: border-box;
    }
    
    .thirdClass{
        width: 100%;
        padding-top: 8%;
        box-sizing: border-box;
    }
    
    .forthClass{
        width: 100%;
        padding-top: 8%;
        box-sizing: border-box;
    }

}

@media(max-width:387px){
    .aboutfirstClass{
        width:15%
    }
    
    .aboutsecondClass{
        width: 60%;
    }
    
}

@media(max-width:277px){
    .aboutfirstClass{
        width:15%
    }
    
    .aboutsecondClass{
        width: 45%;
    }
    
}
